function openNav() {
    $("#myNav").css("width","100%");
    // $("#myNav").css("height","100%");
    $("#myNav .menu-title").css("font-size","2rem");
    $(".closebtn").css("display","block");
    $(".closebtn").css("position","sticky");
    $(".openbtn").css("display","none");
    $(".nav-bullets").css("display","none");
    $(".get-in").css("display","none");
    $("body").css("overflow","hidden");
    $("#cyper-framework").css("display","none");

  }
  
  function closeNav() {
    $("#myNav").css("width","0%");
    // $("#myNav").css("height","0%");
    $("#myNav .menu-title").css("font-size","0px");
    $(".nav-bullets").css("display","block");
    $(".openbtn").css("display","block");
    $(".closebtn").css("display","none");
    $("body").css("overflow","visible");
    $(".get-in").css("display","block");
    $("#cyper-framework").css("display","block");
  }


  function closeNavAndGotoContact() {

    if(window.location.href.includes("index.php")) closeNav();
    
    window.location = "/index.php#footer";
  }












  function validateRecaptcha() {
    var v = grecaptcha.getResponse();
  
    if (v.length == 0) {
      document.getElementById("captcha").innerHTML =
        "You can't leave Captcha Code empty";
      return false;
    } else {
      return true;
    }
  }
  
  
  function validateRecaptcha1() {
    var v2 = grecaptcha.getResponse(1);
  
    if (v2.length == 0) {
      document.getElementById("captcha1").innerHTML =
        "You can't leave Captcha Code empty";
      return false;
    } else {
      return true;
    }
  }
  
  